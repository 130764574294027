import React, { useRef, useState } from "react"

import { twMerge } from "tailwind-merge"

import { Pill } from "~/src/components/BeeKit/Pill"

export type InputWithCannedResponsesProps = {
  containerClassName?: string
  inputClassName?: string
  cannedResponses: Array<string>
  onResponseChange?: (response: string) => void
} & JSX.IntrinsicElements["textarea"]

export const InputWithCannedResponses = (props: InputWithCannedResponsesProps) => {
  const { cannedResponses, onResponseChange, onChange, containerClassName, inputClassName, ...rest } = props

  const messageRef = useRef<HTMLTextAreaElement | null>(null)
  const [messageTextExists, setMessageTextExists] = useState(false)

  const onPillClicked = (message: string) => {
    if (messageRef.current) {
      messageRef.current.value = message
      setMessageTextExists(true)
      onResponseChange?.(message)
    }
  }

  return (
    <div className={twMerge("flex flex-col border rounded border-gray-300", containerClassName)}>
      <textarea
        ref={messageRef}
        className={twMerge(
          "resize-none border-none border-transparent focus:border-transparent focus:ring-0 h-[92px] grow",
          inputClassName
        )}
        onChange={(e) => {
          setMessageTextExists(e.target.value !== "")
          onResponseChange?.(e.target.value)
          onChange?.(e)
        }}
        {...rest}
      />
      <div className={`flex flex-col sm:flex-row mb-3 gap-3 flex-wrap ml-2 ${messageTextExists ? "hidden" : ""}`}>
        {cannedResponses.map((response) => (
          <Pill className="text-gray-500 hover:cursor-pointer" key={response} onClick={() => onPillClicked(response)}>
            <span>{response}</span>
          </Pill>
        ))}
      </div>
    </div>
  )
}
