import React from "react"

import { Bee } from "./BeeKit"
import { ReactionType } from "~/src/components/BeeKit/Reaction"
import { isNil, isntNil } from "~/src/lib/any"

export type ThankYouNoteConfirmationProps = {
  message?: string
  reaction?: ReactionType
}

const Message = (props: { message: string }) => (
  <div className="flex justify-center bg-white rounded-md p-6 z-0">&ldquo;{props.message}&rdquo;</div>
)

const Reaction = (props: { reaction: ReactionType }) => (
  <div className="flex justify-center">
    <Bee.Reaction reaction={props.reaction} className="h-14 w-14" />
  </div>
)

const MessageAndReaction = (props: { message: string; reaction: ReactionType }) => {
  const { reaction, message } = props

  return (
    <div>
      <Bee.Reaction reaction={reaction} className="h-14 w-14 -rotate-[16deg] z-10 -mb-4" />
      <Message message={message} />
    </div>
  )
}

export function ThankYouNoteConfirmation(props: ThankYouNoteConfirmationProps) {
  const { message, reaction } = props

  const bothMessageAndReaction = isntNil(message) && isntNil(reaction)
  const justMessage = isntNil(message) && isNil(reaction)
  const justReaction = isntNil(reaction) && isNil(message)

  return (
    <section className="flex h-full bg-gray-100 p-14 justify-center">
      <div className={`flex flex-col max-w-lg gap-5 md:gap-10 ${justReaction ? "justify-center" : ""}`}>
        <h1 className="text-3xl	sm:text-6xl font-medium text-navy-800 text-center">
          Thanks, we&apos;ll pass your note along!
        </h1>
        {bothMessageAndReaction && <MessageAndReaction message={message} reaction={reaction} />}
        {justMessage && <Message message={message} />}
        {justReaction && <Reaction reaction={reaction} />}
      </div>
    </section>
  )
}
