import React from "react"

import { twMerge } from "tailwind-merge"

import { Bee } from "./BeeKit"

export type MessageQuote = {
  message: string
  author: string
}

export type ThankYouNoteMessageCarouselProps = {
  messageQuotes: MessageQuote[]
}

export function ThankYouNoteMessageCarousel(props: ThankYouNoteMessageCarouselProps) {
  const { messageQuotes } = props

  return (
    <Bee.Carousel className="w-full h-full min-h-[134px]">
      {messageQuotes.map((messageQuote, i) => (
        <QuoteBlock key={i} messageQuote={messageQuote} />
      ))}
    </Bee.Carousel>
  )
}

function QuoteBlock(props: { messageQuote: MessageQuote }) {
  const { messageQuote } = props
  const { message, author } = messageQuote

  const names = author.split(/\s+/)
  const firstInitial = names.length > 0 ? names[0].charAt(0) : ""
  const lastInitial = names.length > 1 ? names.at(-1)!.charAt(0) : ""
  const initials = (firstInitial + lastInitial).toUpperCase()
  const paddingClass = initials.length == 2 ? "p-1" : "py-1 px-2"

  return (
    <div className="flex flex-col gap-4 text-gray-900 grow-0">
      <div className="bg-gray-100 rounded-xl w-full px-4 py-2">
        <div className="w-full text-sm line-clamp-3">{`“${message}”`}</div>
      </div>
      <div className="flex gap-2 text-xs">
        {initials.length > 0 ? (
          <span>
            <span className={twMerge("rounded-full bg-magenta-100 text-magenta-500", paddingClass)}>{initials}</span>
          </span>
        ) : null}
        <span>{author}</span>
      </div>
    </div>
  )
}
