import * as React from "react"

import { twMerge } from "tailwind-merge"

import { KitItemProductField } from "~/src/components/StoreKitInstances/KitItemProductField"
import { KitItemVariantField } from "~/src/components/StoreKitInstances/KitItemVariantField"
import { getFieldName } from "~/src/lib/forms"
import { StoreKitInstanceItem, StoreProduct, StoreProductPhoto } from "~/src/serializedRecords"

interface KitItemFieldsProps {
  kitItem: StoreKitInstanceItem | null | undefined
  kitItemKey: number
  storeProduct: StoreProduct
  includePhoto: boolean
}

interface ProductImageProps {
  photo: StoreProductPhoto
  alt: string
}

function ProductImage({ photo, alt }: ProductImageProps) {
  const { url, medium, large, xlarge } = photo
  return <img srcSet={`${medium.url} 320w, ${large.url} 680w, ${xlarge.url} 960w, ${url} 1980w`} src={url} alt={alt} />
}

const getContainerClasses = (includePhoto) => [
  "p-6",
  ...(includePhoto ? ["with-sidebar"] : []),
  "gap-4",
  "border-t",
  "border-gray-200",
  "first:border-t-0",
]

export function KitItemFields({
  kitItem,
  kitItemKey,
  storeProduct: { fields, name, availableVariantIds, variants, photo },
  includePhoto,
}: KitItemFieldsProps) {
  const fieldPrefix = getFieldName("store_kit_instance", "kit_items", kitItemKey)

  const storeProductFields = fields.length > 0 && (
    <div className="store_product_fields">
      {fields.map((field) => (
        <KitItemProductField
          defaultValue={kitItem?.productFields[field.id]}
          field={field}
          key={field.id}
          inputName={getFieldName(fieldPrefix, "product_fields", field.id)}
        />
      ))}
    </div>
  )

  return (
    <div className={twMerge(getContainerClasses(includePhoto))}>
      <div className="basis-24">{photo && <ProductImage photo={photo} alt={name} />}</div>
      <div className="flex flex-col justify-center">
        <h3 className="text-gray-900 font-medium">{name}</h3>
        <KitItemVariantField
          availableVariantIds={availableVariantIds}
          inputName={getFieldName(fieldPrefix, "variant_id")}
          variantOptions={variants}
        />
        {storeProductFields}
      </div>
    </div>
  )
}
