import React, { PropsWithChildren } from "react"

import { Bee } from "./BeeKit"
import { ImageWithFallback } from "~/src/components"
import { isBlank, isntNil, isPresent } from "~/src/lib/any"

export type OrderRecipientDeliveryProps = {
  placedOn: string
  orderId: number
  deliveryInfo: DeliveryInfo
  orderSummary?: OrderSummary
  feedback?: Feedback
}

type DeliveryInfo = {
  recipientName: string
  recipientEmail?: string
  recipientCompany?: string
  address1?: string
  address2?: string
  addressCity?: string
  addressState?: string
  addressPostalCode?: string
  shippingMethod?: string
  trackingNumber?: string
  trackingUrl?: string
}

type OrderSummary = {
  items: ItemSummary[]
  total: string
}

type ItemSummary = {
  name: string
  imageUrl?: string
}

type Feedback = {
  message?: string
  reaction?: Bee.ReactionType
}

export function OrderRecipientDelivery(props: OrderRecipientDeliveryProps) {
  const { placedOn, orderId, deliveryInfo, orderSummary, feedback } = props
  return (
    <div className="w-full">
      <div className="h-full py-10 flex md:grow-0 justify-center">
        <div className="flex flex-col h-full md:basis-2/5 md:min-w-[612px]">
          <hgroup className="text-center">
            <h4 className="text-gray-500">Placed on {placedOn}</h4>
            <h2 className="text-gray-900 text-4xl font-medium">Order #{orderId}</h2>
          </hgroup>
          <div className="p-6 flex flex-col gap-4">
            <RecipientSection deliveryInfo={deliveryInfo} />
            <OrderSection orderSummary={orderSummary} />

            <FeedbackSection feedback={feedback} />
          </div>
        </div>
      </div>
    </div>
  )
}

function SectionBlock({ name, children }: PropsWithChildren<{ name: string }>) {
  return (
    <section className="border border-gray-200 rounded-lg px-8 py-4 flex flex-col md:flex-row gap-2">
      <div className="basis-1/4 text-gray-500">{name}</div>
      <div className="basis-3/4">{children}</div>
    </section>
  )
}

function RecipientSection({ deliveryInfo }: { deliveryInfo: DeliveryInfo }) {
  const addressLine = (line?: string) => (isPresent(line) ? <div>{line}</div> : null)
  const cityAndStateLine = (city?: string, state?: string) => {
    if (isPresent(city) && isPresent(state)) {
      return addressLine(`${city}, ${state}`)
    }
    if (isPresent(city)) {
      return addressLine(city)
    }
    return addressLine(state)
  }
  const deliveryLine = (shippingMethod?: string, trackingNumber?: string, trackingUrl?: string) => {
    if (isBlank(shippingMethod)) {
      return null
    }

    if (isBlank(trackingNumber)) {
      return addressLine(`Delivered via ${shippingMethod}`)
    }

    if (isBlank(trackingUrl)) {
      return addressLine(`Delivered via ${shippingMethod}: ${trackingNumber}`)
    }

    return (
      <div>
        Delivered via {shippingMethod}:{" "}
        <a href={trackingUrl} className="text-blue-600">
          {trackingNumber}
        </a>
      </div>
    )
  }

  return (
    <SectionBlock name="Recipient">
      <div className="flex flex-col gap-2 text-sm">
        <div>
          {addressLine(deliveryInfo.recipientName)}
          {addressLine(deliveryInfo.recipientEmail)}
        </div>
        <div>
          {addressLine(deliveryInfo.recipientCompany)}
          {addressLine(deliveryInfo.address1)}
          {addressLine(deliveryInfo.address2)}
          {cityAndStateLine(deliveryInfo.addressCity, deliveryInfo.addressState)}
          {addressLine(deliveryInfo.addressPostalCode)}
        </div>
        <div>{deliveryLine(deliveryInfo.shippingMethod, deliveryInfo.trackingNumber, deliveryInfo.trackingUrl)}</div>
      </div>
    </SectionBlock>
  )
}

function OrderSection({ orderSummary }: { orderSummary?: OrderSummary }) {
  return isntNil(orderSummary) ? (
    <SectionBlock name="Order Summary">
      <div className="flex flex-col gap-4">
        {orderSummary.items.map(({ name, imageUrl = "" }, i) => {
          return (
            <div key={i} className="flex gap-4">
              <ImageWithFallback
                src={imageUrl}
                className="flex w-14 h-14 overflow-hidden rounded-lg"
                imgProps={{ className: "h-full w-full object-cover object-center" }}
              />
              <div>{name}</div>
            </div>
          )
        })}
        <div className="flex justify-between">
          <div>Total</div>
          <div>{orderSummary.total}</div>
        </div>
      </div>
    </SectionBlock>
  ) : null
}

function FeedbackSection({ feedback }: { feedback?: Feedback }) {
  return isntNil(feedback) ? (
    <SectionBlock name="Feedback">
      <div className="flex flex-col-reverse md:flex-row gap-2 md:gap-4">
        {isPresent(feedback.message) ? <div className="mr-auto">“{feedback.message}”</div> : null}
        {isPresent(feedback.reaction) ? <Bee.Reaction reaction={feedback.reaction} /> : null}
      </div>
    </SectionBlock>
  ) : null
}
