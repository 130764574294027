import React, { useEffect } from "react"

import { twMerge } from "tailwind-merge"

import { Bee } from "./BeeKit"
import { CollectionItem } from "~/src/types/preferredGift"

export type CollectionItemGridProps = {
  className?: string
  collectionItems: CollectionItem[]
}

export type CollectionItemBatchGridProps = {
  className?: string
  collectionItemBatches: CollectionItem[][]
}

type CollectionItemGridCellsProps = {
  collectionItems: CollectionItem[]
}

export function CollectionItemGrid(props: CollectionItemGridProps) {
  const { className, collectionItems } = props

  return <CollectionItemBatchGrid className={className} collectionItemBatches={[collectionItems]} />
}

export function CollectionItemBatchGrid(props: CollectionItemBatchGridProps) {
  const { className, collectionItemBatches } = props

  return (
    <div className={twMerge("flex flex-wrap gap-2 container", className)}>
      {collectionItemBatches.map((collectionItems, index) => (
        <CollectionItemGridCells key={index} collectionItems={collectionItems} />
      ))}
    </div>
  )
}

function CollectionItemGridCells(props: CollectionItemGridCellsProps) {
  const { collectionItems } = props
  const [loadedItemCount, setLoadedItemCount] = React.useState<number>(0)

  useEffect(() => {
    setLoadedItemCount(0)
  }, [collectionItems])

  const allLoaded = loadedItemCount === collectionItems.length

  return collectionItems.map((item) => (
    <Bee.Tip key={item.id}>
      <Bee.TipTrigger>
        <Bee.Thumbnail
          className={twMerge(allLoaded ? "opacity-100" : "opacity-0", "transition-opacity duration-250")}
          src={item.collectionable.photoLargeUrl}
          onFinal={() => setLoadedItemCount((x) => x + 1)}
          name={item.collectionable.name}
        />
      </Bee.TipTrigger>

      <Bee.TipBubble showArrow>{item.collectionable.name}</Bee.TipBubble>
    </Bee.Tip>
  ))
}
