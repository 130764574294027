import React, { useState } from "react"

import { Bee } from "./BeeKit"
import { ReactionType } from "./BeeKit/Reaction"
import { ContactUs, Modal } from "~/src/components"
import { RailsForm } from "~/src/components/RailsForm"
import { appClient } from "~/src/lib/appClients"

const CANNED_RESPONSES = ["Thank you for the gift!", "I love it!", "This made my day."]

export type ThankYouNoteFormProps = {
  submitPath: string
  formSubmitted: boolean
  onReactionChanged: (reaction: string) => void
  defaultReaction?: string
  onFormSubmit?: (e) => void
  senderName: string
  senderEmail: string
  contactUsUrl: string
  brilliantUrl: string
  viaRecipient: boolean
}

export function ThankYouNoteForm(props: ThankYouNoteFormProps) {
  const { viaRecipient, submitPath, formSubmitted, onReactionChanged, onFormSubmit, defaultReaction } = props

  const [message, setMessage] = useState<string>("")
  const [reaction, setReaction] = useState<string>(defaultReaction || "")
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)

  const setReactionState = (reaction: string) => {
    setReaction(reaction)
    onReactionChanged(reaction)
  }

  return (
    <div>
      <RailsForm method="post" action={submitPath} onSubmit={onFormSubmit}>
        <div className="flex flex-col gap-6 h-fit	w-fit md:m-4 mt-4 bg-white rounded-md md:p-16 p-4">
          <div className="flex flex-col max-w-full">
            <h2 className="text-base font-medium">
              {viaRecipient
                ? "Want to send a personalized message to your sender?"
                : "Want to send a personalized message to your company?"}
            </h2>
            <Bee.InputWithCannedResponses
              name="message"
              containerClassName="mt-3 md:min-h-[138px]"
              onResponseChange={setMessage}
              placeholder="Add your message or get started with one of the responses below"
              cannedResponses={CANNED_RESPONSES}
            />
          </div>

          <div className="flex flex-col gap-4">
            <h2 className="text-base font-medium">
              {viaRecipient ? "What did you think of your gift?" : "What did you think of your order?"}
            </h2>
            <div className="grid md:grid-cols-4 grid-cols-2 gap-4 place-items-stretch">
              <ReactionSelector
                reaction={Bee.ReactionType.HEART_EYES}
                text="Loved it"
                defaultChecked={Bee.ReactionType.HEART_EYES == reaction}
                setReaction={setReactionState}
              />
              <ReactionSelector
                reaction={Bee.ReactionType.PARTY_POPPER}
                text="Great surprise"
                defaultChecked={Bee.ReactionType.PARTY_POPPER == reaction}
                setReaction={setReactionState}
              />
              <ReactionSelector
                reaction={Bee.ReactionType.STAR}
                text="Super cool"
                defaultChecked={Bee.ReactionType.STAR == reaction}
                setReaction={setReactionState}
              />
              <ReactionSelector
                reaction={Bee.ReactionType.SMILING_FACE}
                text="Totally delightful"
                defaultChecked={Bee.ReactionType.SMILING_FACE == reaction}
                setReaction={setReactionState}
              />
            </div>
            <Bee.Button
              type="submit"
              className="text-white text-sm disabled:bg-gray-300"
              disabled={(!reaction.length && !message) || formSubmitted}
            >
              <span>Send note</span>
            </Bee.Button>

            <span className="text-sm text-gray-500">
              Need help with something? Want to get in touch?
              <button type="button" onClick={() => setModalIsOpen(true)} className="text-blue-600">
                &nbsp;Contact Brilliant
              </button>
            </span>
          </div>
        </div>
      </RailsForm>

      <Modal open={modalIsOpen} onClose={() => setModalIsOpen(false)} className="bg-white w-[36rem]">
        <FeedbackModalContent {...props} />
      </Modal>
    </div>
  )
}

type ReactionSelectorProps = {
  reaction: ReactionType
  text: string
  defaultChecked: boolean
  setReaction: (reaction: ReactionType) => void
}

function ReactionSelector({ reaction, text, defaultChecked, setReaction }: ReactionSelectorProps) {
  return (
    <Bee.SelectableIconBox
      name="reaction"
      value={reaction}
      icon={<Bee.Reaction reaction={reaction} className="w-8 h-8" />}
      text={text}
      defaultChecked={defaultChecked}
      onSelect={() => setReaction(reaction)}
    />
  )
}

type FeedbackModalContentProps = {
  senderName: string
  senderEmail: string
  contactUsUrl: string
  brilliantUrl: string
}

function FeedbackModalContent({ contactUsUrl, brilliantUrl, senderName, senderEmail }: FeedbackModalContentProps) {
  const [feedbackSent, setFeedbackSent] = useState<boolean>(false)

  if (feedbackSent) {
    return (
      <div className="flex flex-col gap-2 items-center">
        <h1 className="text-gray-900 text-2xl">Thanks!</h1>
        <p className="text-gray-700 text-center">
          We&apos;ve received your message and a member of our team will reach out shortly.
        </p>
      </div>
    )
  } else {
    return (
      <ContactUs
        url={contactUsUrl}
        brilliantUrl={brilliantUrl}
        name={senderName}
        email={senderEmail}
        formClassName="md:p-0 p-0 md:grid-cols-1"
        onSubmit={async (data) => {
          await appClient.post(contactUsUrl, data)
          setFeedbackSent(true)
        }}
      />
    )
  }
}
