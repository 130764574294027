import React from "react"

import { FolderIcon } from "@heroicons/react/24/outline"
import { twMerge } from "tailwind-merge"

import { useRequestCart } from "~/src/hooks/useRequestCart"

export function NavbarProjectFolder() {
  const { open, count } = useRequestCart()

  return (
    <div className="flex items-center">
      <div className="relative cursor-pointer" onClick={() => open()}>
        <span
          className={twMerge(
            "absolute h-4 w-4 bg-orange-500 text-white rounded-full flex items-center justify-center font-bold text-xs -right-2 top-0",
            count() <= 0 && "hidden"
          )}
        >
          {count()}
        </span>
        <FolderIcon className="w-6 h-6" />
      </div>
    </div>
  )
}
