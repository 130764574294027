import React, { ReactNode, useEffect, useState } from "react"

import { ChevronDownIcon, ChevronUpDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline"

import { ColumnDef, DataTable, DataTableProps } from "./DataTable"
import { PageMeta, PaginationNav } from "./PaginationNav"
import { appClient } from "~/src/lib/appClients"

export type AjaxDataTableProps<RowData> = {
  url: string
  searchParams: URLSearchParams
  renderSupplemental?: (rows: RowData[]) => ReactNode
  columnDefs: SortableColumnDef<RowData>[]
} & Omit<DataTableProps<RowData>, "data" | "columnDefs">

enum SortDir {
  ASC = "asc",
  DESC = "desc",
  NONE = "",
}

type SortProps = {
  field: string
  dir: SortDir
}

export type SortableColumnDef<RowData> = {
  field: string
} & ColumnDef<RowData>

export type TableData<RowData> = {
  data: RowData[]
  pageMeta: PageMeta
}

export function AjaxDataTable<RowData>(props: AjaxDataTableProps<RowData>) {
  const { url, searchParams, columnDefs, renderSupplemental, ...etc } = props

  const [data, setData] = useState<RowData[]>([])
  const [pageMeta, setPageMeta] = useState<PageMeta>()
  const [sort, setSort] = useState<SortProps>({ field: "", dir: SortDir.NONE })
  const [page, setPage] = useState(1)

  const fetchData = async (url: string, options?: object) => {
    const {
      data: { data, pageMeta },
    } = await appClient.get<TableData<RowData>>(url, options)
    setData(data)
    setPageMeta(pageMeta)
  }

  const sortableColumns = columnDefs.map(({ name, field, ...rest }) => {
    return {
      name: name,
      headerNode: <Header name={name} field={field} currentSort={sort} setSort={setSort} />,
      ...rest,
    }
  })

  useEffect(() => {
    const controller = new AbortController()
    const params = new URLSearchParams(searchParams)
    if (sort.field.length) {
      params.append("q[s]", [sort.field, sort.dir].join(" "))
    }
    params.append("page", page.toString())
    fetchData(url, {
      params: params,
      signal: controller.signal,
    }).catch((err) => {
      if (err.name === "AbortError") {
        return
      }

      throw err
    })

    return () => {
      controller.abort()
    }
  }, [sort, page, searchParams])

  return (
    <div className="flex flex-col gap-6">
      <DataTable columnDefs={sortableColumns} data={data} {...etc} />
      {renderSupplemental ? renderSupplemental(data) : <></>}
      {pageMeta ? (
        <div className="flex md:flex-row flex-col gap-4 pt-4 items-center justify-between w-full">
          <p className="md:flex hidden text-gray-600 text-sm">
            Showing {pageMeta.from} to {pageMeta.to} of {pageMeta.count} results
          </p>
          <PaginationNav pageMeta={pageMeta} setPage={setPage} />
          <p className="md:hidden flex text-gray-600 text-sm">
            Showing {pageMeta.from} to {pageMeta.to} of {pageMeta.count} results
          </p>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

function Header({
  name,
  field,
  currentSort,
  setSort,
}: {
  name: string
  field: string
  currentSort: SortProps
  setSort: (sortProp: SortProps) => void
}) {
  const sortDir = currentSort.field == field ? currentSort.dir : SortDir.NONE

  const nextDirection = (currentDirection: SortDir) => {
    switch (currentDirection) {
      case SortDir.ASC:
      case SortDir.NONE:
        return SortDir.DESC
      case SortDir.DESC:
        return SortDir.ASC
    }
  }

  const sortIcon = (currentDirection: SortDir) => {
    switch (currentDirection) {
      case SortDir.ASC:
        return <ChevronUpIcon className="rounded w-4 h-4" />
      case SortDir.NONE:
        return <ChevronUpDownIcon className="rounded w-4 h-4" />
      case SortDir.DESC:
        return <ChevronDownIcon className="rounded w-4 h-4" />
    }
  }

  return (
    <div className="flex items-center gap-2">
      <span className="whitespace-nowrap">{name}</span>
      <button
        onClick={() => {
          setSort({ field: field, dir: nextDirection(sortDir) })
        }}
      >
        {sortIcon(sortDir)}
      </button>
    </div>
  )
}
