// This file includes components used by our client facing UI.
// Please lazily load large components.

import {
  BudgetDisplayPill,
  ContactUs,
  DashboardDebugBox,
  DisplayTagList,
  DropshipEditor,
  LookbookProductFilter,
  OrderRecipientDelivery,
  ProductDetail,
  QuickSendConfirmationContent,
  QuickSendEditDetailsContent,
  QuickSendEditSelectionsContent,
  QuickSendEditStoreContent,
  QuickSendReviewContent,
  ReactionStatistics,
  RequestCart,
  SignIn,
  Spreadsheet,
  StorefrontOrderHistoryControls,
  StoreKitInstanceForm,
  StoreProductWarnings,
  TeamForm,
  TeamsTable,
  ThankYouNoteConfirmation,
  ThankYouNoteLanding,
  ThankYouNoteMessageCarousel,
  ThankYouNotesIndex,
} from "~/src/components"
import { NavbarProjectFolder } from "~/src/components/NavbarProjectFolder"
import { SolutionInstanceToggle } from "~/src/components/Solutions/SolutionInstanceToggle"
import { registerComponents } from "~/src/lib/reactComponentRegistry"

registerComponents({
  BudgetDisplayPill,
  ContactUs,
  CustomTraySolutionForm: import("~/src/components/Solutions/CustomTraySolutionForm"),
  CustomTraySolutionSelectorForm: import("~/src/components/Solutions/CustomTraySolutionSelectorForm"),
  DashboardCatalogController: import("~/src/components/Catalog/DashboardCatalogController"),
  DashboardDebugBox,
  DashboardLookbooksController: import("~/src/components/DashboardLookbooks/DashboardLookbooksController"),
  DisplayTagList,
  DropshipEditor,
  LookbookProductFilter,
  NavbarProjectFolder,
  OrderRecipientDelivery,
  ProductDetail,
  QuickSendConfirmationContent,
  QuickSendEditDetailsContent,
  QuickSendEditSelectionsContent,
  QuickSendEditStoreContent,
  QuickSendReviewContent,
  ReactionStatistics,
  RequestCart,
  SignIn,
  SolutionInstanceToggle,
  Spreadsheet,
  StorefrontOrderHistoryControls,
  StoreKitInstanceForm,
  StoreProductWarnings,
  TeamForm,
  TeamsTable,
  ThankYouNoteConfirmation,
  ThankYouNoteLanding,
  ThankYouNoteMessageCarousel,
  ThankYouNotesIndex,
})
