import React, { PropsWithChildren } from "react"

import { twMerge } from "tailwind-merge"

export type DashBoxProps = PropsWithChildren<{ className?: string }>

export function DashBox(props: DashBoxProps) {
  const { className, children } = props

  return <div className={twMerge("flex flex-col gap-4 bg-white rounded-lg p-6", className)}>{children}</div>
}

export type DashBoxHeadingProps = PropsWithChildren<{ className?: string; title?: string }>

export function DashBoxHeading(props: DashBoxHeadingProps) {
  const { className, title, children } = props

  return (
    <hgroup className={twMerge("flex justify-between items-center", className)}>
      {children ? children : <h2 className="text-2xl font-bold">{title}</h2>}
    </hgroup>
  )
}
