import React, { PropsWithChildren } from "react"

import { Disclosure } from "@headlessui/react"
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid"

import { summarizeSelections } from "../../lib/quickSend"
import { ButtonLink } from "../BeeKit/ButtonLink"
import { CopyText } from "../BeeKit/CopyText"
import { WorkflowContainer } from "../WorkflowContainer"
import { QuickSendSummary } from "./QuickSendSummary"
import { ProductSelection } from "~/src/components/ProductSelection"
import { WorkflowHeading } from "~/src/components/WorkflowHeading"
import { Format } from "~/src/lib/format"
import { QuickSend, QuickSendKind } from "~/src/types/preferredGift"

export type QuickSendConfirmationContentProps = {
  imageUrl: string
  quickSend: QuickSend
}

function Header({ use, kind }: { use: "email" | "link"; kind: QuickSendKind }) {
  if (kind == QuickSendKind.Surprise) {
    return (
      <WorkflowHeading
        title="We're on it!"
        subtitle="Our team will start working on your shipment soon. You can check the status of this order at any time from the preferred gift campaign page."
      />
    )
  } else if (use == "email") {
    return (
      <WorkflowHeading
        title="Your gift has been sent!"
        subtitle="You recipients will receive their email invitation soon."
      />
    )
  } else if (use == "link") {
    return (
      <WorkflowHeading title="Your link is ready!" subtitle="Copy the link below and share it with your recipient." />
    )
  }
}

function CopyUrl({ use, recipientUrl }: { use: string; recipientUrl?: string }) {
  if (use == "link" && recipientUrl) {
    return <CopyText value={recipientUrl} />
  }
}

function EmailText({ use, emailBody }: { use: string; emailBody: string }) {
  if (use == "email") {
    return (
      <div>
        <h4 className="font-medium">Email message:</h4>
        <p className="whitespace-pre-wrap">{emailBody}</p>
      </div>
    )
  }
}

function NotecardText({ notecardMessage }: { notecardMessage?: string }) {
  if (notecardMessage) {
    return (
      <div>
        <h4 className="font-medium">Notecard:</h4>
        <p className="whitespace-pre-wrap">{notecardMessage}</p>
      </div>
    )
  }
}

function ConfirmationSection({ title, children }: PropsWithChildren<{ title: string }>) {
  return (
    <div className="border rounded-lg border-gray-200 flex md:flex-row flex-col md:gap-4 gap-2 md:px-6 p-4">
      <p className="text-gray-500 w-24 min-w-[6rem] text-sm">{title}</p>
      <div className="flex flex-col text-gray-900 text-sm grow">{children}</div>
    </div>
  )
}

function MessageSection({ use, quickSend }: { use: string; quickSend: QuickSend }) {
  const { kind, notecardMessage, emailBody } = quickSend

  if (kind == QuickSendKind.Surprise) {
    if (notecardMessage) {
      return (
        <ConfirmationSection title="Notecard">
          <p className="whitespace-pre-wrap">{notecardMessage}</p>
        </ConfirmationSection>
      )
    }
  } else {
    return (
      <ConfirmationSection title="Message">
        <div className="flex flex-col gap-2">
          <EmailText use={use} emailBody={emailBody} />
          <NotecardText notecardMessage={notecardMessage} />
        </div>
      </ConfirmationSection>
    )
  }
}

export function Expandable(props: PropsWithChildren<{ count: number }>) {
  const { children, count } = props

  return (
    <div className="flex flex-col gap-2">
      <Disclosure>
        <Disclosure.Button>
          {({ open }) => (
            <p className="text-blue-600 text-sm text-left flex items-center">
              Recipients {count}
              {open ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
            </p>
          )}
        </Disclosure.Button>
        <Disclosure.Panel>{children}</Disclosure.Panel>
      </Disclosure>
    </div>
  )
}

export function QuickSendConfirmationContent(props: QuickSendConfirmationContentProps) {
  const { imageUrl, quickSend } = props
  const { kind, orderRecipients, selections, emailBody, notecardMessage } = quickSend
  const { senderName, senderEmail, use, recipientUrl } = orderRecipients[0]
  const { chosenSelections, giftedSelections, estimatedTotal } = summarizeSelections(selections)

  const estimatedTotalForAll = estimatedTotal * orderRecipients.length
  const [{ selectable }] = selections
  const { customCurrencyUnit } = selectable

  return (
    <div className="h-full flex flex-col">
      <WorkflowContainer className="flex flex-col gap-6">
        <div className="flex flex-col items-center pt-3 pb-4 gap-4">
          <img src={imageUrl} className="h-32 w-32" />
          <Header kind={kind} use={use} />
        </div>

        <CopyUrl use={use} recipientUrl={recipientUrl} />

        <div className="flex flex-col gap-4">
          <ConfirmationSection title="Products">
            <QuickSendSummary
              chosenSelections={chosenSelections}
              giftedSelections={giftedSelections}
              quickSendKind={kind}
            />
            <div className="flex flex-col text-sm pt-4 border-t border-gray-200">
              <p className="flex font-medium text-gray-700 justify-between">
                <p>Estimated total:</p>
                <p>{Format.currency(estimatedTotalForAll, customCurrencyUnit)}</p>
              </p>
              <p className="text-gray-500">
                ({Format.currency(estimatedTotal, customCurrencyUnit)} per send x {orderRecipients.length} recipients)
              </p>
              <div className="text-gray-500 text-[10px] italic leading-tight">
                This is the estimated total based on the items you selected. The final amount deducted from your budget
                will be adjusted to accommodate shipping cost.
              </div>
            </div>
          </ConfirmationSection>

          <ConfirmationSection title="Sender">
            <p>{senderName}</p>
            <p>{senderEmail}</p>
          </ConfirmationSection>

          <ConfirmationSection title="Recipients">
            <Expandable count={orderRecipients.length}>
              <ProductSelection.RecipientTable orderRecipients={orderRecipients} />
            </Expandable>
          </ConfirmationSection>

          <MessageSection quickSend={quickSend} use={use} />
        </div>
        <ButtonLink href="/send_gifts" kind="secondary" className="bg-blueslate-200 border-none md:w-fit w-full">
          Send another gift
        </ButtonLink>
      </WorkflowContainer>
    </div>
  )
}
