import React, { ReactNode } from "react"

import { random, sample } from "lodash-es"
import { twMerge } from "tailwind-merge"

import styles from "./ParticleConfetti.module.scss"

const DEFAULT_NUM_PARTICLES = 40

export type ParticleConfettiProps = {
  numParticles?: number
  children: ReactNode
}

export const ParticleConfetti = (props: ParticleConfettiProps) => {
  const { numParticles = DEFAULT_NUM_PARTICLES, children } = props

  const getY = () => random(-30, 40)
  const getX = () => random(0, 95)
  const getScale = () => random(0.5, 4)

  const getRotateDuration = () => random(3, 6)
  const getRotateDegree = () => sample([-360, 360])

  const emojis = Array.from({ length: numParticles }, (_, i) => {
    const scale = getScale()

    const downupDivStyle = {
      bottom: `${getY()}vh`,
      left: `${getX()}vw`,
      "--down-up-duration": `${Math.floor(7 / scale)}s`,
      zIndex: Math.floor(10 * scale),
    } as React.CSSProperties

    const rotatingDivStyle = {
      "--rotate-duration": `${getRotateDuration()}s`,
      "--rotate-deg": `${getRotateDegree()}deg`,
    } as React.CSSProperties

    return (
      <div className={twMerge("absolute", styles.downUp)} style={downupDivStyle} key={i}>
        <div style={{ transform: `scale(${scale})` }}>
          <div className={styles.rotate} style={rotatingDivStyle}>
            {children}
          </div>
        </div>
      </div>
    )
  })

  return <div className="fixed w-[200vw] h-screen top-0 left-0 pointer-events-none">{emojis}</div>
}
