import { useEffect, useState } from "react"

import { appClient } from "~/src/lib/appClients"

export function useFlipper(feature: string) {
  const [enabled, setFeatureEnabled] = useState(false)

  useEffect(() => {
    const checkFeature = async () => {
      try {
        const {
          data: { state: state },
        } = await appClient.get<{ state: string }>(`/api/i/flipper/features/${feature}`)

        setFeatureEnabled(state === "on")
      } catch (e) {
        if (e.response.status == 404) {
          console.error(`No such feature flag: ${feature}. Please make sure feature flag's name is correct.`)
        } else {
          throw e
        }
      }
    }

    checkFeature()
  }, [feature])

  return enabled
}
